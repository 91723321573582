import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as offersActions from '@/_store/offers/offers.actions';
import {
	selectAvailableBoardsDialog,
	selectFilterOptions,
	selectIsOptionsAreEmpty,
	selectNextOffers,
	selectNextPageIsLoading,
	selectNightCounters,
	selectNightsRows,
	selectOfferList,
	selectOfferListCount,
	selectOfferListHeader,
	selectOfferListHeaderByKey,
	selectOffersDateRange,
	selectOffersOffset,
	selectOptions,
	selectOwnTransport,
	selectParticipantCounters,
	selectParticipantsRows,
	selectSelectedDateRange,
	selectSelectedOptionsParams,
	selectSelectedValues,
	selectSorting,
} from '@/_store/offers/offers.selectors';
import {
	RowValue,
	TopSearchDropdownItemMode,
} from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';
import { combineLatest, Observable, take } from 'rxjs';
import {
	BaseFilter,
	DEFAULT_PAGINATION_LIMIT,
	DEFAULT_PAGINATION_OFFSET,
	NumericDictionary,
	OfferDateRange,
	OfferList,
	Selected,
	SORTING_OPTIONS,
} from '@/_store/offers/offers.types';

@Injectable({
	providedIn: 'root',
})
export class OffersFacade {
	offersDateRange$ = this.store.select(selectOffersDateRange);
	selectedDateRange$ = this.store.select(selectSelectedDateRange);
	participantsRows$ = this.store.select(selectParticipantsRows);
	nightsRows$ = this.store.select(selectNightsRows);
	participantCounters$ = this.store.select(selectParticipantCounters);
	nightCounters$ = this.store.select(selectNightCounters);
	offersList$ = this.store.select(selectOfferList);
	availableBoards$ = this.store.select(selectAvailableBoardsDialog);
	urlParams$ = this.store.select(selectSelectedOptionsParams);
	selected$ = this.store.select(selectSelectedValues);
	headerValues$ = this.store.select(selectOfferListHeader);
	offerListCount$ = this.store.select(selectOfferListCount);
	ownTransport$ = this.store.select(selectOwnTransport);
	sorting$ = this.store.select(selectSorting);
	offset$ = this.store.select(selectOffersOffset);
	nextOffers$ = this.store.select(selectNextOffers);
	nextPageIsLoading$ = this.store.select(selectNextPageIsLoading);
	filterOptions$ = this.store.select(selectFilterOptions);
	offerFiltersAreEmpty$ = this.store.select(selectIsOptionsAreEmpty);

	constructor(private readonly store: Store) {}

	fetchOfferFilter(): void {
		this.store.dispatch(offersActions.fetchOfferFilter());
	}

	fetchOfferList(params: string, paramsForNextPage: string): void {
		this.store.dispatch(offersActions.fetchOffers({ params, paramsForNextPage }));
	}

	fetchNextOfferList(params: string): void {
		this.store.dispatch(offersActions.fetchNextOffers({ params }));
	}

	fetchBoards(id: number): void {
		this.store.dispatch(offersActions.fetchBoards({ id }));
	}

	getOptions(mode: TopSearchDropdownItemMode): Observable<BaseFilter[]> {
		return this.store.select(selectOptions(mode));
	}

	getHeaderValue(key: string): Observable<string | number> {
		return this.store.select(selectOfferListHeaderByKey(key));
	}

	addLocationsFilterOption(id: number): void {
		this.store.dispatch(
			offersActions.addLocationFilterOption({
				id,
			}),
		);
	}

	removeLocationsFilterOption(id: number): void {
		this.store.dispatch(offersActions.removeLocationFilterOption({ id }));
	}

	addKindsFilterOption(id: number): void {
		this.store.dispatch(
			offersActions.addKindsFilterOption({
				id,
			}),
		);
	}

	removeKindsFilterOption(id: number): void {
		this.store.dispatch(offersActions.removeKindsFilterOption({ id }));
	}

	addBoardsFilterOption(id: number): void {
		this.store.dispatch(
			offersActions.addBoardsFilterOption({
				id,
			}),
		);
	}

	removeBoardsFilterOption(id: number): void {
		this.store.dispatch(offersActions.removeBoardsFilterOption({ id }));
	}

	addTagsFilterOption(id: number): void {
		this.store.dispatch(
			offersActions.addTagsFilterOption({
				id,
			}),
		);
	}

	removeTagsFilterOption(id: number): void {
		this.store.dispatch(offersActions.removeTagsFilterOption({ id }));
	}

	addConveniencesFilterOption(id: number): void {
		this.store.dispatch(offersActions.addConveniencesFilterOption({ id }));
	}

	removeConveniencesFilterOption(id: number): void {
		this.store.dispatch(offersActions.removeConveniencesFilterOption({ id }));
	}

	addStopsFilterOption(id: number): void {
		this.store.dispatch(offersActions.addStopsFilterOption({ id }));
	}

	removeStopsFilterOption(id: number): void {
		this.store.dispatch(offersActions.removeStopsFilterOption({ id }));
	}

	updateOffersDateRange(dateRange: OfferDateRange): void {
		this.store.dispatch(offersActions.updateOffersDateRange({ dateRange }));
	}

	updateAdultsCount(adults: number): void {
		this.store.dispatch(offersActions.updateAdultsCount({ adults }));
	}

	updateChildrenCount(children: number): void {
		this.store.dispatch(offersActions.updateChildrenCount({ children }));
	}

	updateNights(row: RowValue): void {
		this.store.dispatch(offersActions.updateNights({ row }));
	}

	updateNightsOptions(option: RowValue): void {
		this.store.dispatch(offersActions.updateNightsOptions({ option }));
	}

	updateQueryParams(params: string): void {
		this.store.dispatch(offersActions.updateQueryParams({ params }));
	}

	updateOwnTransport(ownTransport: boolean): void {
		this.store.dispatch(offersActions.updateOwnTransport({ ownTransport }));
	}

	updateSearchFilter(search: string): void {
		this.store.dispatch(offersActions.updateSearchFilter({ search }));
	}

	clearAvailableBoards(): void {
		this.store.dispatch(offersActions.clearAvailableBoards());
	}

	clearOfferList(): void {
		this.store.dispatch(offersActions.clearOfferList());
	}

	clearBoardsFilter(): void {
		this.store.dispatch(offersActions.clearBoardsFilter());
	}

	clearConveniencesFilter(): void {
		this.store.dispatch(offersActions.clearConveniencesFilter());
	}

	clearStopsFilter(): void {
		this.store.dispatch(offersActions.clearStopsFilter());
	}

	clearLocationsFilter(): void {
		this.store.dispatch(offersActions.clearLocationsFilter());
	}

	clearKindsFilter(): void {
		this.store.dispatch(offersActions.clearKindsFilter());
	}

	clearTagsFilter(): void {
		this.store.dispatch(offersActions.clearTagsFilter());
	}

	clearSearchFilter(): void {
		this.store.dispatch(offersActions.clearSearchFilter());
	}

	setDefaultNights(): void {
		this.store.dispatch(offersActions.setDefaultNights());
	}

	setDefaultChildrenValue(): void {
		this.store.dispatch(offersActions.setDefaultChildrenValue());
	}

	clearSelectedOptions(): void {
		this.store.dispatch(offersActions.clearSelectedOptions());
	}

	setInitialState(): void {
		this.store.dispatch(offersActions.setInitialState());
	}

	setSorting(sorting: keyof typeof SORTING_OPTIONS): void {
		this.store.dispatch(offersActions.setSorting({ sorting }));
	}

	updateOffset(offset: number): void {
		this.store.dispatch(offersActions.updateOffset({ offset }));
	}

	updateOfferList(offersList: NumericDictionary<OfferList>): void {
		this.store.dispatch(offersActions.updateOffers({ offersList }));
	}

	clearOffersNextPage(): void {
		this.store.dispatch(offersActions.clearOffersNextPage());
	}

	updateNextPageIsLoading(nextPageIsLoading: boolean): void {
		this.store.dispatch(offersActions.updateNextPageIsLoading({ nextPageIsLoading }));
	}

	reloadOfferList(): void {
		this.updateOffset(DEFAULT_PAGINATION_OFFSET);
		combineLatest([this.urlParams$, this.offset$])
			.pipe(take(1))
			.subscribe(([params, offset]) => {
				this.clearOfferList();
				this.fetchOfferList(
					params + `&limit=${DEFAULT_PAGINATION_LIMIT}&offset=${offset}`,
					params + `&limit=${DEFAULT_PAGINATION_LIMIT}&offset=${offset + DEFAULT_PAGINATION_LIMIT}`,
				);
				this.updateQueryParams(params);
			});
	}

	updateSelectedOptions(selected: Selected): void {
		this.store.dispatch(offersActions.updateSelectedValues({ selected }));
	}
}
