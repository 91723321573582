import {
	ExtendedTopSearchItemModeWithSearchInput,
	TopSearchDropdownItemMode,
} from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';

export interface ITopSearchConfig {
	id: TopSearchDropdownItemMode;
	text: string;
}

export interface ITopSearchAdvancedConfig {
	id: TopSearchDropdownItemMode | ExtendedTopSearchItemModeWithSearchInput;
	text: string;
}

export const TopSearchMainConfig: ITopSearchConfig[] = [
	{
		id: TopSearchDropdownItemMode.LOCATIONS,
		text: 'Kierunek',
	},
	{
		id: TopSearchDropdownItemMode.DATE_RANGE,
		text: 'Data',
	},
	{
		id: TopSearchDropdownItemMode.STOPS,
		text: 'Miasto wyjazdu',
	},
	{
		id: TopSearchDropdownItemMode.PARTICIPANTS,
		text: 'Uczestnicy',
	},
];

export const TopSearchAdvancedConfig: ITopSearchConfig[] = [
	{
		id: TopSearchDropdownItemMode.KINDS,
		text: 'Rodzaj wycieczki',
	},
	{
		id: TopSearchDropdownItemMode.BOARDS,
		text: 'Wyżywienie',
	},
	{
		id: TopSearchDropdownItemMode.TAGS,
		text: 'Tagi',
	},
	{
		id: TopSearchDropdownItemMode.CONVENIENCES,
		text: 'Udogodnienia',
	},
	{
		id: TopSearchDropdownItemMode.NIGHTS,
		text: 'Ilość nocy',
	},
];

export const TopSearchMobileConfig: ITopSearchConfig[] = [
	{
		id: TopSearchDropdownItemMode.KINDS,
		text: 'Rodzaj wycieczki',
	},
	...TopSearchMainConfig,
];

export const ExtendedTopSearchAdvancedSearchConfig = [
	{
		id: ExtendedTopSearchItemModeWithSearchInput.SEARCH,
		text: 'Wyszukaj',
	},
];

export const TopSearchHeaderConfig: ITopSearchAdvancedConfig[] = [
	...TopSearchMainConfig,
	...TopSearchAdvancedConfig,
	...ExtendedTopSearchAdvancedSearchConfig,
];
