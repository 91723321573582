/**
 * @param params - routing queryParams object
 * @param config - optional, prefix is to pass productId if needed,
 *                 and includedParams is to exclude params that we're not interested.
 *                 If includedParams === null, then all params will be used.
 * 				   Sufix is to add additional param, like limit.
 * @returns - params that you can use in the API request
 */
export function createApiURL(
	params: { [key: string]: string },
	config?: { prefix?: string; includedParams?: string[]; sufix?: string },
): string {
	const prefix = config?.prefix ?? '';
	const sufix = config?.sufix ?? '';
	const includedParams = config?.includedParams ?? null;
	const url = Object.keys(params)
		.filter((key) => includedParams === null || includedParams.includes(key))
		.map((key) => key + '=' + params[key])
		.sort((a, b) => a.localeCompare(b))
		.join('&');

	return '?' + prefix + url + sufix;
}
