/**
 * @param obj - object with any values you want to convert
 * @returns - object where all values are strings
 */
export function convertObjectValuesToStrings<T = unknown>(obj: T): Record<string, string> {
	const result: Record<string, string> = {};

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			result[key] = obj[key] ? obj[key].toString() : '';
		}
	}

	return result;
}
