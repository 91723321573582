// OfferFilter interfaces
import { TermStatus } from '@/common/enum';

export interface BaseFilter {
	id: number;
	name: string;
}

export interface OfferDateRange {
	upper: string;
	lower: string;
}

export interface OfferFilter {
	boards: BaseFilter[];
	promotionCatalogs: BaseFilter[];
	nights: { min: number[]; max: number[] };
	defaultNights: number[];
	adults: number[];
	children: number[];
	roomType: BaseFilter[];
	stops: BaseFilter[];
	products: BaseFilter[];
	locations: BaseFilter[];
	kinds: BaseFilter[];
	conveniences: BaseFilter[];
	tags: BaseFilter[];
	ownTransport: boolean;
	count: number;
	offersDateRange: OfferDateRange;
	search: string;
}

export interface ApiOfferFilter extends Omit<OfferFilter, 'nights' | 'defaultNights'> {
	nights: number[];
}

export interface Selected {
	boards: number[];
	nights: Nights;
	adults: number;
	children: number;
	roomType: number[];
	stops: number[];
	locations: number[];
	kinds: number[];
	conveniences: number[];
	tags: number[];
	ownTransport: boolean;
	offersDateRange: OfferDateRange;
	search: string;
}

export interface Nights {
	min: number;
	max: number;
}

// Offer interfaces
export interface Board {
	board: number;
	name: string;
}

export interface Stop {
	name: string | null;
	stop: number | null;
	departureDatetime: string;
	returnDatetime: string;
}

export interface Location {
	id: number;
	name: string;
	kind: string;
}

export interface Multimedia {
	id: string;
	name: string;
	url: string;
}

export interface Convenience {
	id: number;
	name: string;
	convenience: string;
	multimedia: Multimedia | null;
}

export interface Product {
	id: number;
	name: string;
	locations: Location[];
	multimedias: Multimedias[];
	conveniences: Convenience[];
}

export interface Multimedias {
	multimedia: Multimedia;
	order: number;
}

export interface DateRange {
	lower: string;
	upper: string;
	bounds: string;
}

export interface Term {
	product: Product;
	dateRange: DateRange;
	promotion: boolean;
	nightsStay: number;
	status: TermStatus;
}

export interface RoomType {
	id: number;
	name: string;
	code: string;
}

export interface Room {
	term: Term;
	roomType: RoomType | null;
	adults: number;
	children: number;
}

export interface Catalog {
	id: number;
	name: string;
	multimedia: string;
}

export interface Promotion {
	id: number;
	name: string;
	catalog: Catalog;
	description: string;
	method: string;
	methodValue: number;
}

export interface OfferList {
	board: Board;
	stop: Stop;
	room: Room;
	fullPrice: number;
	catalogPrice: number;
	promotion: Promotion | null;
}

// www/offer/ interface - I will remove this, when backend modify offer endpoint
interface ApiPriceSetting {
	mainPrice: number;
	mainBoard: {
		id: number;
		name: string;
	};
	services: ApiService[];
}

interface ApiService {
	optionName: string;
	value: number;
	countingMethod: string;
}

interface ApiBoard {
	board: number;
	name: string;
	boardPrice: number;
	currency: string;
	priceSetting: ApiPriceSetting;
}

interface ApiTransport {
	departureId: number;
	returnId: number;
	stopName: string;
	stopAddress: string;
	arrivalTime: string;
	departureTime: string;
}

interface ApiStop {
	transport: ApiTransport;
	name: string;
	address: string;
	stop: number;
	departureDatetime: string;
	returnDatetime: string;
	fee: number;
	kind: string;
	amount: number;
	amountFree: number;
	days: string;
}

interface ApiMultimedia {
	id: string;
	name: string;
	url: string;
}

interface ApiDescriptionHeader {
	id: number;
	name: string;
	multimedia: ApiMultimedia;
}

interface ApiDescription {
	descriptionHeader: ApiDescriptionHeader;
	description: string;
}

interface ApiSurchargeCurrency {
	id: number;
	name: string;
	code: string;
	exchangeRatePln: number;
}

interface ApiSurchargeBoard {
	id: number;
	fee: number;
	board: number;
	currency: ApiSurchargeCurrency;
}

interface ApiSurchargeProgram {
	id: number;
	currency: ApiSurchargeCurrency;
	fee: number;
	description: string;
}

interface ApiConvenience {
	id: number;
	name: string;
	convenience: string;
	multimedia: ApiMultimedia;
}

interface ApiTag {
	id: number;
	name: string;
	tag: string;
}

interface ApiProductKind {
	id: number;
	name: string;
}

interface ApiLocation {
	id: number;
	name: string;
	kind: string;
}

interface ApiProduct {
	id: number;
	name: string;
	code: string;
	productKind: ApiProductKind;
	organization: number;
	locations: ApiLocation[];
	multimedias: {
		multimedia: ApiMultimedia;
		order: number;
	}[];
	descriptions: ApiDescription[];
	surchargeBoard: ApiSurchargeBoard;
	surchargeProgram: ApiSurchargeProgram;
	conveniences: ApiConvenience[];
	tags: ApiTag[];
}

interface ApiTerm {
	termId: number;
	product: ApiProduct;
	dateRange: { bounds: string; lower: string; upper: string };
	sale: boolean;
	www: boolean;
	merlinx: boolean;
	promotion: boolean;
	nightsStay: number;
	status: TermStatus;
}

interface ApiRoomType {
	id: number;
	name: string;
	code: string;
}

interface ApiRoom {
	term: ApiTerm;
	roomType: ApiRoomType;
	fee: number;
	adults: number;
	children: number;
}

interface ApiCatalog {
	id: number;
	name: string;
	multimedia: string;
}

interface ApiPromotion {
	id: number;
	name: string;
	catalog: ApiCatalog;
	description: string;
	method: string;
	methodValue: number;
}

export interface ApiOfferList {
	board: ApiBoard;
	stop: ApiStop;
	room: ApiRoom;
	fullPrice: number;
	catalogPrice: number;
	promotion: ApiPromotion;
}

export interface NumericDictionary<T> {
	[key: number]: T;
}

export interface OfferListParams {
	adults: number;
	children: number;
	boards: number[];
	conveniences: number[];
	kinds: number[];
	locations: number[];
	stops: number[];
	tags: number[];
	nights_min: number;
	nights_max: number;
	date_range: ParamsDateRange;
	own_transport: boolean;
	product_name: string;
}

export type ParamsDateRange = `${string},${string}` | '';

export interface OfferListHeader {
	key: string;
	name: string;
	value: string | number;
	defaultOption: boolean;
}

export const SORTING_OPTIONS = {
	'-full_price,departure_date': 'Cena malejąco',
	'full_price,departure_date': 'Cena rosnąco',
	'departure_date,full_price': 'Data od najwcześniejszej',
	'-departure_date,full_price': 'Data od najpóźniejszej',
};

export const DEFAULT_SORTING: keyof typeof SORTING_OPTIONS = 'departure_date,full_price';

export const DEFAULT_PAGINATION_OFFSET = 0;

export const DEFAULT_PAGINATION_LIMIT = 10;
